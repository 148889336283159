import React from "react";
import Text from "../../components/Text";
import Button from "../../components/Button";

function ThankYouPopUp({ open, setOpen, title, message, btnText }) {
  return (
    <>
      {open && (
        <div className="fixed inset-0 z-50 bg-[#00000088] bg-opacity-5 flex items-center justify-center">
          <div className=" text-darkBlue bg-white flex justify-center items-center flex-col text-center px-11 py-12 max-w-[419px] shadow-lightBlue">
            <Text
              highlightedColor="yellow"
              as="h2"
              highlightedText={[]}
              className="text-3xl text-blue text-center font-primary font-bold pb-4"
            >
              {title}
            </Text>

            <Text
              as="p"
              className="text-basemd text-blue leading-8 text-center font-opensans"
            >
              {message}
            </Text>
            <Button
              onClick={() => setOpen(false)}
              title={btnText}
              className="w-full text-lg text-white text-center bg-yellow font-opensans font-medium py-3 mt-10 uppercase rounded-full"
            />
          </div>
        </div>
      )}
    </>
  );
}
export default ThankYouPopUp;
