import React, { useState } from "react";
import BoxContainer from "../../components/BoxContainer";
import Text from "../../components/Text";
import Button from "../../components/Button";
import textResolver from "../../core/utils/text-resolver";
import { useForm } from "react-hook-form";
import { useGetInTouchBanner } from "../../hooks/Forms/useGetInTouchBanner";
import { useLocation } from "@gatsbyjs/reach-router";
import ThankYouPopUp from "../../components/PopUp/ThankYou";
import formCommonPostData from "../../core/form-common-post-data";

function CtaWithForm({
  data,
  btnStyle,
  background = "bg-gradient-to-r from-[#f84d94] to-[#fc883b]",
}) {
  const { dataType = 0 } = data;
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const { mutateAsync } = useGetInTouchBanner();

  const onSubmit = (data) => {
    const postData1 = {
      name: data.name,
      email: data.email,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      setOpen(true);
      reset();
    });
  };

  return (
    <BoxContainer background={background} className="px-5 2xl:px-0 py-20">
      <div
        className="max-w-4xl mx-auto flex flex-col items-center"
        id="link-orange"
      >
        <Text
          as="h2"
          highlightedText={
            dataType === 1
              ? data.highlightWords
              : textResolver(data, "highlightWords")
          }
          highlightedColor={data.highlightedColor}
          className="text-5xl text-white font-primary font-semibold text-center pb-11"
        >
          {dataType === 1 ? data.title : textResolver(data, "title")}
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex xs:flex-col sm:flex-row gap-3 pb-3">
            <input
              type="text"
              name="name"
              // placeholder={
              //   dataType === 1
              //     ? data.placeholder.name
              //     : textResolver(data.placeholder, "name")
              // }
              placeholder="Name"
              className={`text-xxs text-metal-gray font-opensan border-none border-0 rounded ${
                errors.name ? "focus:ring-red focus:border-red" : ""
              }`}
              {...register("name", {
                required: true,
              })}
            />
            <input
              type="email"
              name="email"
              // placeholder={
              //   dataType === 1
              //     ? data.placeholder.email
              //     : textResolver(data.placeholder, "email")
              // }
              placeholder="Email Address"
              className={`text-xxs text-metal-gray font-opensan border-none border-0 rounded min-w-16 ${
                errors.email ? "focus:ring-red focus:border-red" : ""
              }`}
              {...register("email", {
                required: true,
              })}
            />
            <input type="hidden" id="zc_gad" name="zc_gad" value="" />
            <Button
              // title={
              //   dataType === 1 ? data.btnText : textResolver(data, "btnText")
              // }
              title="submit"
              className={`text-base text-white font-opensans font-medium text-center uppercase flex items-center justify-center px-7 py-3 transition-colors ${btnStyle}`}
            />
          </div>
          <Text as="div" className="text-xxs text-white font-opensans">
            {/* {dataType === 1
              ? data.policyAgreement
              : textResolver(data, "policyAgreement")} */}
            {/* *By submitting, you agree to LexTranscribe's Privacy Policy. */}
            {dataType === 1 ? data.subtitle : textResolver(data, "subtitle")}
          </Text>
        </form>
      </div>
      <ThankYouPopUp open={open} setOpen={setOpen} dataType={dataType} />
    </BoxContainer>
  );
}
export default CtaWithForm;
