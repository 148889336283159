import React, { useRef, useLayoutEffect, useState } from "react";
import LazyLoad from "react-lazyload";

const OptimizedImage = (props) => {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
      });
    }
  }, []);

  return (
    <div ref={targetRef}>
      <LazyLoad height={"100%"} offset={100}>
        <img
          width={dimensions.width}
          src={props.src + "?width=" + dimensions.width}
          alt={props.alt}
          className={props.className}
        />
      </LazyLoad>
    </div>
  );
};

export default OptimizedImage;
