import React, { useState } from "react";
import Text from "../../components/Text";
import Button from "../../components/Button";
import { useForm } from "react-hook-form";
import { useLocation } from "@gatsbyjs/reach-router";
import { useGetInTouchBanner } from "../../hooks/Forms/useGetInTouchBanner";
import { Link } from "gatsby";
import ThankYouPopUp from "./ThankYouPopUp";
import textResolver from "../../core/utils/text-resolver";
import formCommonPostData from "../../core/form-common-post-data";
// import Loader from "components/loader";
function ContactusMobilePopup({
  openModal,
  setOpenModal,
  data,
  thankYouPopUpData,
  dataType = 0,
}) {
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const { mutateAsync } = useGetInTouchBanner();

  const onSubmit = (data) => {
    const postData1 = {
      name: data.name,
      email: data.email,
      message: data.message,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      setOpen(true);
      setOpenModal(false);
      reset();
    });
  };
  return (
    <>
      {openModal && (
        <div className="0px:w-[335px] md:w-[420px] xs:mx-auto bg-white py-8 px-8 shadow-lg relative">
          <img
            src="https://tomedes.gumlet.io/frontend/images/home-en/popup_assets/close_dark.svg"
            alt="close"
            className="absolute right-5 top-5 cursor-pointer"
            onClick={() => setOpenModal(false)}
          />
          <div className="flex flex-col">
            <Text
              as="div"
              highlightedText={
                dataType === 1
                  ? data.highlightWords
                  : textResolver(data, "highlightWords")
              }
              highlightedColor={data.highlightedColor}
              className="text-[30px] text-blue text-center font-primary font-bold pb-2"
            >
              {dataType === 1 ? data.title : textResolver(data, "title")}
            </Text>
            <Text
              as="div"
              className="text-xxs text-blue text-center font-primary pb-5"
            >
              {dataType === 1
                ? data.description
                : textResolver(data, "description")}
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-2 text-xxs text-black">
                <label className="block text-xxs text-midBlue font-opensans w-full">
                  <p>
                    {dataType === 1
                      ? data.label1
                      : textResolver(data, "label1")}
                    <span className="text-red">{data.star}</span>
                  </p>
                  <input
                    type="text"
                    name="name"
                    className="mt-1 block w-full border-light-silver border text-[14px] font-opensans py-2 px-3"
                    {...register("name", {
                      required: true,
                    })}
                  />
                </label>
                {errors.name && (
                  <span className="text-red text-xxs font-opensans">
                    {dataType === 1
                      ? data.fieldRequired
                      : textResolver(data, "fieldRequired")}
                  </span>
                )}
                <label className="block text-xxs text-midBlue font-opensans">
                  <p>
                    {dataType === 1
                      ? data.label2
                      : textResolver(data, "label2")}
                    <span className="text-red">{data.star}</span>
                  </p>
                  <input
                    type="email"
                    name="email"
                    className="mt-1 block w-full border-light-silver border text-[14px] font-opensans py-2 px-3"
                    {...register("email", {
                      required: true,
                    })}
                  />
                </label>
                {errors.email && (
                  <span className="text-red text-xxs font-opensans">
                    {dataType === 1
                      ? data.fieldRequired
                      : textResolver(data, "fieldRequired")}
                  </span>
                )}
                <label className="block text-xxs text-midBlue font-opensans">
                  <p>
                    {dataType === 1
                      ? data.label3
                      : textResolver(data, "label3")}
                  </p>
                  <textarea
                    className="mt-1 block w-full  py-2 px-3 border-light-silver border text-[14px] font-opensans"
                    rows="3"
                    name="message"
                    {...register("message")}
                  ></textarea>
                </label>
                <p className="text-[10px] md:text-xs leading-4 text-metal-gray font-opensans">
                  {/* {dataType === 1
                    ? data.userAgreement
                    : textResolver(data, "userAgreement")}
                  <Link to={data.userAgreementCta.link} className="text-yellow">
                    {dataType === 1
                      ? data.userAgreementCta.text
                      : textResolver(data.userAgreementCta, "text")}
                  </Link> */}
                  *By submitting this, you agree to LexTranscribe’s Privacy
                  Policy.
                </p>
                <input type="hidden" id="zc_gad" name="zc_gad" value="" />
                <Button
                  title={
                    dataType === 1
                      ? data.btnText
                      : textResolver(data, "btnText")
                  }
                  className="w-full text-lg text-white text-center bg-yellow font-opensans font-medium py-3 mt-5 uppercase rounded-full"
                />
              </div>
            </form>
          </div>
        </div>
      )}
      <ThankYouPopUp
        open={open}
        setOpen={setOpen}
        title={
          dataType === 1
            ? thankYouPopUpData.title
            : textResolver(thankYouPopUpData, "title")
        }
        message={
          dataType === 1
            ? thankYouPopUpData.message
            : textResolver(thankYouPopUpData, "message")
        }
        btnText={
          dataType === 1
            ? thankYouPopUpData.btnText
            : textResolver(thankYouPopUpData, "btnText")
        }
      />
    </>
  );
}
export default ContactusMobilePopup;
