import React, { useState } from "react";
import Text from "../../components/Text";
import Button from "../../components/Button";
import { useForm } from "react-hook-form";
import { useLocation } from "@gatsbyjs/reach-router";
import { useGetInTouchBanner } from "../../hooks/Forms/useGetInTouchBanner";
import { Link } from "gatsby";
import ThankYouPopUp from "./ThankYouPopUp";
import textResolver from "../../core/utils/text-resolver";
import popUpBanner from "../../assets/temporary-images/legal-transcription-popup-banner.png";
import formCommonPostData from "../../core/form-common-post-data";
// import Loader from "components/loader";
function ContactusPopup({
  openModal,
  setOpenModal,
  data,
  thankYouPopUpData,
  dataType = 0,
}) {
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const { mutateAsync } = useGetInTouchBanner();

  const onSubmit = (data) => {
    const postData1 = {
      name: data.name,
      email: data.email,
      message: data.message,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      setOpen(true);
      setOpenModal(false);
      reset();
    });
  };
  return (
    <>
      {openModal && (
        <div className="fixed inset-0 z-50 bg-[#00000088] bg-opacity-5 flex items-center justify-center">
          <div className="flex">
            <div>
              <img
                src={dataType === 1 ? data.image : textResolver(data, "image")}
                // src="https://livetomedes.s3.amazonaws.com/ServicePageBannerImage/1656569712book.jpg?width=413"
                alt="pop-up"
                className="w-full h-full"
              />
            </div>
            <div className="0px:w-[348px] md:w-[420px] xs:mx-auto bg-white py-8 px-8 shadow-lightBlue relative">
              <img
                src="https://tomedes.gumlet.io/frontend/images/home-en/popup_assets/close_dark.svg"
                alt="close"
                className="absolute right-5 top-5 cursor-pointer"
                onClick={() => setOpenModal(false)}
              />
              <div className="flex flex-col">
                <Text
                  as="div"
                  // highlightedText={
                  //   dataType === 1
                  //     ? data.highlightWords
                  //     : textResolver(data, "highlightWords")
                  // }
                  // highlightedColor={data.highlightedColor}
                  className="text-[30px] text-blue text-center font-primary font-bold pb-2"
                >
                  {dataType === 1 ? data.title : textResolver(data, "title")}
                  {/* Contact Us */}
                </Text>
                <Text
                  as="div"
                  className="text-xxs leading-[24px] text-blue text-center font-primary pb-5"
                >
                  {dataType === 1
                    ? data.description
                    : textResolver(data, "description")}
                  {/* Enter your details and we will get back to you within a few
                  minutes. */}
                </Text>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-col text-xxs text-black">
                    <label className="block text-xxs text-midBlue font-opensans w-full">
                      <p>
                        {dataType === 1
                          ? data.label1
                          : textResolver(data, "label1")}
                        {/* <span className="text-red">{data.star}</span> */}
                        {/* Name */}
                        <span className="text-red">*</span>
                      </p>
                      <input
                        type="text"
                        name="name"
                        className="mt-1 block w-full border-light-silver border text-[14px] font-opensans py-2 px-3"
                        {...register("name", {
                          required: true,
                        })}
                      />
                    </label>
                    <span
                      className={`text-red text-xs font-opensans pt-[2px] pb-1 ${
                        errors.name ? "visible" : "invisible"
                      }`}
                    >
                      {dataType === 1
                        ? data.fieldRequired
                        : textResolver(data, "fieldRequired")}
                      {/* This field is required */}
                    </span>
                    <label className="block text-xxs text-midBlue font-opensans">
                      <p>
                        {dataType === 1
                          ? data.label2
                          : textResolver(data, "label2")}
                        {/* <span className="text-red">{data.star}</span> */}
                        {/* Email address */}
                        <span className="text-red">*</span>
                      </p>
                      <input
                        type="email"
                        name="email"
                        className="mt-1 block w-full border-light-silver border text-[14px] font-opensans py-2 px-3"
                        {...register("email", {
                          required: true,
                        })}
                      />
                    </label>
                    <span
                      className={`text-red text-xs font-opensans pt-[2px] pb-1 ${
                        errors.name ? "visible" : "invisible"
                      }`}
                    >
                      {dataType === 1
                        ? data.fieldRequired
                        : textResolver(data, "fieldRequired")}
                      {/* This field is required */}
                    </span>
                    <label className="block text-xxs text-midBlue font-opensans mb-2">
                      <p>
                        {dataType === 1
                          ? data.label3
                          : textResolver(data, "label3")}
                        {/* Tell us about your needs */}
                      </p>
                      <textarea
                        className="mt-1 block w-full  py-2 px-3 border-light-silver border text-[14px] font-opensans"
                        rows="3"
                        name="message"
                        {...register("message")}
                      ></textarea>
                    </label>
                    <p className="text-[10px] md:text-xs text-metal-gray font-opensans">
                      {/* {dataType === 1
                        ? data.userAgreement
                        : textResolver(data, "userAgreement")}
                      <Link
                        to={data.userAgreementCta.link}
                        className="text-yellow"
                      >
                        {dataType === 1
                          ? data.userAgreementCta.text
                          : textResolver(data.userAgreementCta, "text")}
                      </Link> */}
                      *By submitting this, you agree to LexTranscribe’s Privacy
                      Policy.
                    </p>
                    <input type="hidden" id="zc_gad" name="zc_gad" value="" />
                    <Button
                      title={
                        dataType === 1
                          ? data.btnText
                          : textResolver(data, "btnText")
                      }
                      // title="SUBMIT"
                      className="w-full text-lg text-white text-center bg-yellow font-opensans font-medium py-3 mt-6 uppercase rounded-full"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <ThankYouPopUp
        open={open}
        setOpen={setOpen}
        title={
          dataType === 1
            ? thankYouPopUpData.title
            : textResolver(thankYouPopUpData, "title")
        }
        message={
          dataType === 1
            ? thankYouPopUpData.message
            : textResolver(thankYouPopUpData, "message")
        }
        btnText={
          dataType === 1
            ? thankYouPopUpData.btnText
            : textResolver(thankYouPopUpData, "btnText")
        }
      />
    </>
  );
}
export default ContactusPopup;
