import React, { useState, useEffect } from "react";
import BoxContainer from "../../components/BoxContainer";
import Text from "../../components/Text";
import textResolver from "../../core/utils/text-resolver";
import OptimizedImage from "../../hooks/OptimizedImage";
import Header from "./Header";
import iconResolver from "../../core/icon-resolver";
import ContactusPopup from "../Popup/Contactus";
import ContactusMobilePopup from "../Popup/ContactusMobile";

const Banner = ({
  data,
  background = "bg-circleGradient bg-cover",
  headerData,
  contactusPopUpData,
  thankYouPopUpData,
  sealData,
  imgMaxWidth = "max-w-full",
}) => {
  const { dataType = 0 } = data;
  const [openModal, setOpenModal] = useState(false);
  const [normalHeader, setNormalHeader] = useState(true);

  const listenToScroll = () => {
    let heightToHideFrom = 0;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      normalHeader && setNormalHeader(false);
    } else {
      setNormalHeader(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <>
      {!normalHeader && <Header data={headerData} stickyHeader={true} />}
      <BoxContainer background={background} className="0px:px-5 2xl:px-0">
        {normalHeader && <Header data={headerData} />}
        <div className="max-w-6xl mx-auto pt-6 pb-10">
          <div className="lg:grid lg:grid-cols-5 lg:gap-20 items-center">
            <div className="col-span-3">
              <Text
                as="h1"
                className="0px:text-4xl sm:text-5xl text-blue text-left font-primary font-extrabold pb-5"
              >
                {dataType === 1 ? data.title : textResolver(data, "title")}
              </Text>
              <Text
                as="div"
                className="text-base leading-8 text-blue font-opensans pb-7"
                id="link-orange"
              >
                {dataType === 1
                  ? data.description
                  : textResolver(data, "description")}
              </Text>
              <button
                className={`0px:w-full sm:w-auto text-base text-white bg-yellow font-opensans font-medium uppercase px-7 py-3 text-center rounded-full flex items-center justify-center md:hover:bg-lightYellow transition-colors ${
                  openModal ? "0px:bg-blue md:bg-yellow" : ""
                }`}
                onClick={() => setOpenModal(true)}
              >
                get started
                <span
                  className={`${
                    openModal ? "visible" : "invisible"
                  } pl-4 md:hidden`}
                >
                  {iconResolver("arrow-circle-down-white")}
                </span>
              </button>
            </div>
            <div className={`0px:hidden lg:block col-span-2 ${imgMaxWidth}`}>
              <OptimizedImage
                src={
                  dataType === 1 ? data.imgurl : textResolver(data, "imgurl")
                }
                alt="banner"
                className="w-full max-w-full"
              />
            </div>
            {contactusPopUpData && (
              <div className="md:hidden pt-8">
                <ContactusMobilePopup
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  data={contactusPopUpData}
                  thankYouPopUpData={thankYouPopUpData}
                />
              </div>
            )}
          </div>
          {sealData.desktop && (
            <div className="w-full 0px:hidden lg:grid lg:grid-cols-4 gap-y-6 justify-items-center pt-7">
              {sealData.desktop.map((item, i) => {
                return (
                  <div className="flex items-center gap-3" key={i}>
                    {iconResolver(item.icon)}
                    <Text className="text-xxs text-blue font-opensans font-semibold">
                      {item.text}
                    </Text>
                  </div>
                );
              })}
            </div>
          )}
          {sealData.mobile && (
            <div className="w-full 0px:flex 0px:gap-7 sm:gap-10 lg:hidden pt-7">
              {sealData.mobile.map((item, i) => {
                return (
                  <div className="flex items-center gap-3" key={i}>
                    {iconResolver(item.icon)}
                    <Text className="text-xxs text-blue font-opensans font-semibold">
                      {item.text}
                    </Text>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </BoxContainer>
      {contactusPopUpData && (
        <div className="0px:hidden md:block">
          <ContactusPopup
            openModal={openModal}
            setOpenModal={setOpenModal}
            data={contactusPopUpData}
            thankYouPopUpData={thankYouPopUpData}
          />
        </div>
      )}
    </>
  );
};
export default Banner;
