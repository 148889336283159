import { useMutation } from "react-query";
import { endpoints } from "../../core/utils/endpoints";
import http from "../../core/utils/http";

const addGetInTouch = (postData) => {
  return http().post(endpoints.forms.getInTouchBanner, postData);
};

export function useGetInTouchBanner() {
  return useMutation(addGetInTouch, {
    onError: (errorData) => {
      const errorMessage = errorData?.error?.message || errorData?.message;
      alert(errorMessage);
    },
  });
}
